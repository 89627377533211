// Reverse propagation link of card when user click on card
(function(document, window) {
    document.addEventListener("DOMContentLoaded", function() {
        document.addEventListener('click', function reversePropagationLink(e) {

            const card = e.target.closest('.card');

            if(!card || !card.querySelector('a[href]')) return;

            const href = card.querySelector('a[href]').getAttribute('href');

            window.location.href = href;
        });
    })
})(document, window);